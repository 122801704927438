import { Environment } from './environment.interface';

export const environment: Environment = {
  production: false,
  manpower_sso:
    'https://api.dev.mpg.manpowergroupassessments.com/sso/oauth/authorize?client_id={fixedValue}&redirect_uri=https://insights.manpowergroupassessments.com/manpower/sso/aouth',
  api_url: 'https://api.dev.mpg.manpowergroupassessments.com/api/v1',
  api_internal: 'https://api.dev.mpg.manpowergroupassessments.com/api/v1',
  share_url: 'https://www.dev.mpg.manpowergroupassessments.com/share',
  quiz_api_url: 'https://quizapi.dev.mpg.manpowergroupassessments.com/api/v1/quiz',
  media_url: 'https://quizapi.dev.mpg.manpowergroupassessments.com',
  sentry_dsn: 'https://a69ec400706c4d1bac2c9877ab4a5c47@sentry.q-tests.com/18',

  recaptchaSiteKey: '6Le7_OIhAAAAAPWMpQwrdfjWuBkTlCinThLbxCRL',
  translationsUrl: 'https://mpg-dev-backend.s3.eu-central-1.amazonaws.com/translations',

  facebook_app_id: '311292133319158',
  google_app_id: '1091001942500-3ojcp9v970rtvvd8a301jc50u4nfkkvh.apps.googleusercontent.com',
  linkedin_app_id: '772j7fo8fyx738',
  concerto: {
    media_domain: 'https://cdn.manpowergroupassessments.com/cat',
    host_domain: 'https://cat.dsfork.q-tests.com',
    client_id: '1_3je2obuh902s8k84gow44k8k4coogkswww8s48scss0kwgow48',
    client_secret: '3oove4hxo96o8co808sk4gkow00o0gwg8808wwskwc4ok844c0',
  },
};
